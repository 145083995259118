body {
  margin: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.container{
  height: 80% !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.LOCAL{
  background-color: #45d52c;
  color: white;
  padding: 8px;
  /* font-weight: bold; */
  border-radius: 20px;
  margin-right: 20px;
}

.DEV{
  background-color: #2c73d5;
  color: white;
  padding: 12px;
  font-weight: bold;
  border-radius: 10px;
  margin-right: 20px;
}

.PROD{
  background-color: #d5572c;
  color: white;
  padding: 12px;
  font-weight: bold;
  border-radius: 10px;
  margin-right: 20px;
}

.ActionButtons{
  margin-left: 9px !important;
  margin-right: 9px !important;
  font-size: large !important;
}

.QrButton{
  margin-right: 7.5px;
  font-size: large;
}

/* table */
.ant-table {
  font-size: 14px !important;
}

/* row data */
.ant-table-tbody > tr > td {
  height: 6px !important;
  padding: 6px !important;
  /*background-color: #ffffff !important;*/
}

.ant-table-thead > tr > th {
  height: 5px !important;
  padding: 4px !important;
}

.ant-tag {
  font-weight: bold !important;
  font-size: 15px !important;
  border-width: 3px !important;
  border-radius: 4px !important;
}

.ant-table-column-sorters {
  padding: 5px 5px !important;
}

.activeCard {
  border-color: blue !important;
  border-width: 1.5px !important;
}

.clickRowStyl{
  font-weight: bold !important;
  filter: contrast(90%) !important;
}

.ant-table-tbody>.clickRowStyl:hover>td{
  font-weight: bold !important;
  filter: contrast(90%) !important;
}

.ant-table-tbody>tr{
  cursor: pointer !important;
}

.ant-table-pagination.ant-pagination {
  margin: 10px 0 0 0 !important;
}

.events {
   margin: 0;
   padding: 0;
   list-style: none;
}

.events .ant-badge-status {
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.notes-month {
  font-size: 28px;
  text-align: center;
}
.notes-month section {
         font-size: 28px;
}
